body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    background-color: transparent;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: transparent;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
