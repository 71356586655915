a {
  text-decoration: underline;
  font-weight: bold;
}

a:hover {
  text-decoration: none;
  font-weight: bold;
  color: #0056b3; /* Optional: Change the color on hover */
}

.chatbot {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  max-height: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  font-family: Signika;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  z-index: 9999; /* Ensure the chat window is the topmost */
}

.chatbot.closed {
  height: 40px;
}

.chatbot.open {
  height: 400px;
}

.chatbot.expanded {
  width: 600px;
  height: 600px;
}

.chatbot-header {
  background: #007bff;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
}

.chatbot-controls {
  display: flex;
  gap: 5px;
}

.chatbot-controls button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.chatbot-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #f1f1f1;
  overflow: hidden;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px; /* Smaller font size */
}

.message.user {
  background: #007bff;
  color: #fff;
  align-self: flex-end;
}

.message.assistant {
  background: #e1e1e1;
  color: #000;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  border-top: 1px solid #ccc;
  padding: 10px;
  background: #fff;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: 12px; /* Smaller font size */
}

.chat-input button {
  padding: 10px 20px;
  border: none;
  background: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 12px; /* Smaller font size */
}

.quick-replies {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.quick-replies button {
  font-size: 12px; /* Smaller font size */
}

@media (max-width: 768px) {
  .chatbot {
    width: 90%;
    right: 5%;
    bottom: 0; /* Ensure it sticks to the bottom of the page */
    height: 40px; /* Minimized height */
  }

  .chatbot.expanded {
    width: 90%;
    height: 80%;
    right: 5%;
  }

  .chatbot-header {
    padding: 8px;
  }

  .chat-input input {
    padding: 8px;
  }

  .chat-input button {
    padding: 8px 16px;
  }

  .message {
    margin: 3px 0;
    padding: 8px;
  }

  .quick-replies {
    padding: 8px;
  }
}
